.cardShadow {
    background: #FFFFFF;
    box-shadow: 0px 16px 44px rgba(82, 82, 82, 0.1);
    border-radius: 20px;
    padding: 25px;
    transition: all 0.25s ease-in-out;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 25px;
    /* height: auto; */
    /* width: 34vw; */
    overflow-y: auto;
    max-width: 60vw;
    margin-top: 25px;
    margin-bottom: 15px;
    max-height: 100vh;
}


.vDivider {
    border-right: 0.25px solid #3c3c3c;
    height: 100%;
    min-height: 70px;
}

.ecLogo {
    width: 200px;
    padding: 15px;
}

.aluLogo {
    margin-left: 15px;
    width: 150px;
    height: 30px;
    background-color: #002E6D;
    padding: 15px;
}

.input {
    border-radius: 13px;
    height: 36px;
    width: 300px;
}

.diseaseSelect {
    width: 385px !important;
    height: 36px;
}

.customDisease {
    width: 270px;
    border-radius: 13px;
}

.fieldWidth {
    min-width: 385px;
}

@media (max-width:992px) {
    .cardShadow {
        margin-top: 100px;
        margin-bottom: 25px;
        max-width: 80vw;
    }

    .fieldWidth {
        min-width: 300px;
    }

    .vDivider {
        min-height: 50px;
    }

    .cardShadow {
        height: auto;
        width: 80vw;
    }

    .ecLogo {
        width: 130px;
        padding: 0px;
    }

    .aluLogo {
        margin-left: 10px;
        width: 85px;
        height: 17px;
    }

    .input {
        width: 205px;
    }

    .diseaseSelect {
        width: 300px !important;
    }

    .customDisease {
        width: 185px;
    }

}


.formLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin: 0px;
}

.formValue {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin: 0px;
}

.formTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: 25px;
    margin-top: -10px;
    margin: 20px 0px;
}

.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: -10px;
    /* margin: 20px 0px; */
}

.subTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #262626;
    margin-bottom: 35px;
    /* margin: 20px 0px; */
}